/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { GlobalState } from "common/global";
import CameraOrbitOperator from "../custom-operator/camera-orbit.operator";
import GeneralOperator from "../custom-operator/general.operator";
import BaseOperator from "./base.operator";

export default class SelectOperator extends BaseOperator {

    generalOperator: GeneralOperator;

    generalHandle: any;

    cameraOrbitOperator: CameraOrbitOperator;

    orbitHandle: any;
    constructor(viewer: Communicator.WebViewer, viewId: string) {
        super(viewer);
        this.Name = 'select';
        this.generalOperator = new GeneralOperator(viewer);
        this.generalHandle = viewer.operatorManager.registerCustomOperator(this.generalOperator);
        this.cameraOrbitOperator = new CameraOrbitOperator(viewer, viewId);
        this.orbitHandle = viewer.operatorManager.registerCustomOperator(this.cameraOrbitOperator);
        this.cameraOrbitOperator.setOrbitFallbackMode(Communicator.OrbitFallbackMode.OrbitTarget);
        this.cameraOrbitOperator.setOrbitTarget(viewer.view.getCamera().getTarget());
        this.cameraOrbitOperator.setPrimaryButton(Communicator.Button.None);
    }

    public UpdateTargetPoint(newTarget: Communicator.Point3) {
        this.cameraOrbitOperator.UpdateTargetPoint(newTarget);
    }

    /**
     * Active
     */
    public Active() {
        const { operatorManager } = this.Viewer;
        if (this.Status === false) {
            operatorManager.push(this.generalHandle);
            operatorManager.push(this.orbitHandle);
            this.cameraOrbitOperator.setMapping(Communicator.Button.Left);
            this.cameraOrbitOperator.addMapping(Communicator.Button.Middle, Communicator.KeyModifiers.Shift);
            operatorManager.getOperator(Communicator.OperatorId.Pan).setMapping(Communicator.Button.Middle);
            operatorManager.getOperator(Communicator.OperatorId.Pan).addMapping(Communicator.Button.Right);
            operatorManager.getOperator(Communicator.OperatorId.Pan).addMapping(Communicator.Button.Left, Communicator.KeyModifiers.Shift);
            operatorManager.getOperator(Communicator.OperatorId.Orbit).setMapping(Communicator.Button.None);
            this.Viewer.model.getModelBounding(true, false).then((box) => {
                if (box) this.UpdateTargetPoint(box.center());
            });
            this.Status = true;
            GlobalState.mapOperatorCursor.set('select', 'default');
            GlobalState.primaryOperator.set(this.Viewer, 'select');
            super.SetCursor('default');
        }
        if (this.Status === true) { // using for update the mode changed
            if (this.Viewer.sheetManager.isDrawingSheetActive()) {
                operatorManager.getOperator(this.orbitHandle).setMapping(Communicator.Button.None, Communicator.KeyModifiers.Control);
                this.cameraOrbitOperator.DeleteTargetPointMarker();
            }
        }
    }

    /**
     * InActive
     *
     */
    public InActive() {
        if (this.Status === true) {
            this.Escape();
            this.cameraOrbitOperator.unCallBacks();
            this.Viewer.operatorManager.remove(this.generalHandle);
            this.Viewer.operatorManager.unregisterCustomOperator(this.generalHandle);

            this.Viewer.operatorManager.remove(this.orbitHandle);
            this.Viewer.operatorManager.unregisterCustomOperator(this.orbitHandle);

            this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Orbit).setMapping(Communicator.Button.Left);
            this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Pan).setMapping(Communicator.Button.Right);
            this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Navigate).clearMapping();
            this.ResetDefautOperators();
            this.Status = false;
            super.SetCursor('');
        }
    }

    public async ShowSubInformation(isShow: boolean) {
        if (!this.cameraOrbitOperator) return;
        if (!isShow) {
            await this.cameraOrbitOperator.DeleteTargetPointMarker();
        }
    }

    public Escape(): boolean {
        return true;
    }

    GetIdOperator(): number {
        return this.orbitHandle;
    }
}
