import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { BimApi } from "api/bim.api"
import { FileInfo, FlatTreeNode, RawFlatTreeNode } from "common/define"
import { GlobalState } from "common/global"
import { RootEpic } from "common/type-state"
import MathHelper from "container/pdf-viewer/helper/math.helper"
import CModel from "container/viewer3d/extends/CModel"
import { merge } from "rxjs"
import { catchError, filter, switchMap, withLatestFrom, concatMap } from "rxjs/operators"
import Utils from "utils/utils"
import ModelTreeHelper from "./helper/modelTree.helper"
interface TreeState {
    currModelFileId: ModelFileId,
    idUpdate: UniqueId,
    loadingTreeData: boolean;
    treeLoadingMap: {
        [modelFileId: string]: boolean;
    },
    treeMapData: {
        [modelFileId: string]: Map<string, FlatTreeNode>;
    },
    // treeMapPersistentId: { // linking 2d
    //     [modelFileId: string]: Map<string, FlatTreeNode>;
    // },
    visibilityChangedCallback: {
        [modelFileId: string]: boolean;
    },
    resetHiddenMap: {
        [modelFileId: string]: boolean;
    },
    updateShowHide: boolean | undefined;
    currentTreeMap: Map<string, FlatTreeNode>;
    updateHoopsVisibleState: {
        visState: Communicator.VisibilityState,
        id: string,
    } | undefined;
    isRefresh: string;
}

const initTreeState: TreeState = {
    currModelFileId: '',
    idUpdate: '',
    loadingTreeData: false,
    treeLoadingMap: {},
    treeMapData: {},
    // treeMapPersistentId: {}, // linking 2d
    visibilityChangedCallback: {},
    resetHiddenMap: {},
    updateShowHide: undefined,
    currentTreeMap: new Map<string, FlatTreeNode>(),
    updateHoopsVisibleState: undefined,
    isRefresh: '',
}

const treeSlice = createSlice({
    name: 'treeState',
    initialState: initTreeState,
    reducers: {
        // updateCurrentTreeData(state, action: PayloadAction<ModelFileId>) {
        //     state.currModelFileId = action.payload;
        //     state.idUpdate = Communicator.UUID.create();
        //     state.loadingTreeData = false;
        //     state.treeLoadingMap[action.payload] = false;
        // },
        // fetchDataTree(state, action: PayloadAction<{ modelFileId: ModelFileId, fileName: string | undefined, viewId: ViewId }>) {
        //     state.loadingTreeData = true;
        //     state.treeLoadingMap[action.payload.modelFileId] = true;
        // },
        // fetchDataTreeByViewIdActive(state, action: PayloadAction<ViewId>) {
        //     state.loadingTreeData = true;
        // },
        // setLoading(state, action: PayloadAction<boolean>) {
        //     state.loadingTreeData = action.payload;
        // },
        // error(state, action: PayloadAction<string>) {
        //     state.loadingTreeData = false;
        //     state.idUpdate = '';
        //     state.currModelFileId = '';
        //     state.treeLoadingMap[action.payload] = false;
        // },
        setTreeLoadingMap(state, action: PayloadAction<{ modelFileId: string, loading: boolean }>) {
            const { modelFileId, loading } = action.payload;
            state.treeLoadingMap[modelFileId] = loading;
        },
        fetchDataTreeFlat(state, action: PayloadAction<{ fileInfo: FileInfo, rootNode?: number }>) { return },
        setTreeMapData(state, action: PayloadAction<{ modelFileId: ModelFileId, treeMap: Map<string, FlatTreeNode> }>) {
            const { modelFileId, treeMap } = action.payload;
            state.treeMapData[modelFileId] = treeMap;
        },
        storeTreeMapData(state, action: PayloadAction<{ [modelFileId: string]: Map<string, FlatTreeNode> }>) {
            const { payload } = action;
            state.treeMapData = payload;
        },
        storeTreeLoadingMap(state, action: PayloadAction<{ [modelFileId: string]: boolean; }>) {
            const { payload } = action;
            state.treeLoadingMap = payload;
        },
        // setTreeMapPersistentId(state, action: PayloadAction<{ modelFileId: ModelFileId, treeMap: Map<string, FlatTreeNode> }>) {  // linking 2d
        //     const { modelFileId, treeMap } = action.payload;
        //     state.treeMapPersistentId[modelFileId] = treeMap;
        // },
        toggleVisibilityChangedCallback(state, action: PayloadAction<ModelFileId>) {
            const modelFileId = action.payload;
            state.visibilityChangedCallback[modelFileId] = !state.visibilityChangedCallback[modelFileId];
        },
        resetHiddenMap(state, action: PayloadAction<undefined>) { return },
        setUpdateShowHide(state, action: PayloadAction<boolean | undefined>) {
            state.updateShowHide = action.payload;
        },
        setCurrentTreeMap(state, action: PayloadAction<Map<string, FlatTreeNode>>) {
            state.currentTreeMap = action.payload;
        },
        setUpdateHoopsVisibleState(state, action: PayloadAction<Communicator.VisibilityState>) {
            state.updateHoopsVisibleState = {
                visState: action.payload,
                id: Communicator.UUID.create(),
            }
        },
        deleteStateByViewIdTree(state, action: PayloadAction<ViewId>) {
            return;
        },
        refreshTree(state, action: PayloadAction<undefined>) {
            state.isRefresh = Communicator.UUID.create();
        }
    },
})

// const fetchDataTree$: RootEpic = (action$, state$) => action$.pipe(
//     filter(fetchDataTree.match),
//     withLatestFrom(state$),
//     mergeMap(([reFilter, state]) => {
//         const { modelFileId, fileName, viewId } = reFilter.payload;
//         const extension = Utils.getFileExtension(fileName);
//         if (GlobalState.isFileChild(viewId) && extension === 'rvt') {
//             return [
//                 treeSlice.actions.setLoading(false),
//                 treeSlice.actions.setTreeLoadingMap({ modelFileId, loading: false }),
//             ]
//         }
//         const api: Observable<FilesTree | null> = BimApi.getTreeStringWithExtension(modelFileId, extension);
//         return api.pipe(
//             mergeMap(res => {
//                 if (res) {
//                     let type: TreeConvertType = 'default';
//                     if (FileExtension.Revit === extension || FileExtension.IFC === extension) {
//                         type = 'category'
//                     }
//                     return TreeHelper.mapTreeWorker(res, modelFileId, type);
//                 }
//                 GlobalState.mapTreeData.set(modelFileId, { data: [], extra: {} });
//                 GlobalState.GenerateMapTreeDataNode(modelFileId);
//                 return throwError('Err request');
//             }),
//             map(mapTree => {
//                 GlobalState.mapTreeData.set(modelFileId, mapTree); // used for old tree, hierarchy tree
//                 GlobalState.GenerateMapTreeDataNode(modelFileId);
//                 return treeSlice.actions.updateCurrentTreeData(modelFileId)
//             }),
//             catchError(err => [error(modelFileId)])
//         )
//     })
// );

// const fetchDataTreeByViewIdActive$: RootEpic = (action$, state$) => action$.pipe(
//     filter(fetchDataTreeByViewIdActive.match),
//     withLatestFrom(state$),
//     switchMap(([re, state]) => {
//         const filesOrigin = state.filesList.filesOrigin;
//         const viewId = re.payload;
//         const { modelFileId, fileInfo } = TreeHelper.treeActiveId(viewId, filesOrigin);
//         if (modelFileId && fileInfo) {
//             const getDataMap = GlobalState.getTreeDataByModelFileId(modelFileId);
//             if (!getDataMap) {
//                 return [fetchDataTree({ modelFileId, fileName: fileInfo.originalFile, viewId })]
//             } else {
//                 return [treeSlice.actions.updateCurrentTreeData(modelFileId)]
//             }
//         }
//         return [error('')]
//     })
// );

const deleteStateByViewIdTree$: RootEpic = (action$, state$) => action$.pipe(
    filter(deleteStateByViewIdTree.match),
    withLatestFrom(state$),
    concatMap(([action, state]) => {
        const modelFileId = action.payload;
        const treeData = Utils.unsetPathObject(state.tree.treeMapData, modelFileId);
        const reTreeLoadingMap = Utils.unsetPathObject(state.tree.treeLoadingMap, modelFileId);
        return [
            storeTreeMapData(treeData || {}),
            storeTreeLoadingMap(reTreeLoadingMap || {})
        ];
    })
)

const fetchDataTreeFlat$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchDataTreeFlat.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { fileInfo, rootNode } = action.payload;
        const { modelFileId, cacheFilename, originalFile, filename } = fileInfo;
        const fileName = originalFile ?? cacheFilename ?? filename;
        const extension = Utils.getFileExtension(fileName);
        // if (GlobalState.isFileChild(viewId) && extension === 'rvt') {
        //     return [
        //         treeSlice.actions.setTreeLoadingMap({ modelFileId, loading: false }),
        //     ]
        // }
        const mainModelViewId = state.multiViewer.viewActive.viewId;
        const mainViewer = GlobalState.getViewer3D(mainModelViewId);
        if (!mainViewer) return [];

        const rootId = rootNode ? (mainViewer.model.getNodeParent(rootNode) ?? 0) : MathHelper.getPositiveRootNode(mainViewer);//mainViewer.model.getAbsoluteRootNode();
        return merge(
            [treeSlice.actions.setTreeLoadingMap({ modelFileId, loading: true })],
            BimApi.getFlatModelTree(modelFileId, extension).pipe(
                switchMap(val => {
                    const parseData: RawFlatTreeNode[] = JSON.parse(val);
                    // console.log(JSON.stringify(parseData))
                    const { treeMapData } = ModelTreeHelper.generateTreeMaps(parseData, String(rootId), fileName, modelFileId);
                    return [
                        treeSlice.actions.setTreeMapData({ modelFileId, treeMap: treeMapData }),
                        // treeSlice.actions.setTreeMapPersistentId({ modelFileId, treeMap: treeMapPersistentId }), // linking 2d
                        treeSlice.actions.setTreeLoadingMap({ modelFileId, loading: false }),
                    ]
                }),
                catchError(e => [
                    treeSlice.actions.setTreeMapData({ modelFileId, treeMap: new Map<string, FlatTreeNode>() }),
                    // treeSlice.actions.setTreeMapPersistentId({ modelFileId, treeMap: new Map<string, FlatTreeNode>() }), // linking 2d
                    treeSlice.actions.setTreeLoadingMap({ modelFileId, loading: false }),
                ])
            )
        )

    })
);

const resetHiddenMap$: RootEpic = (action$, state$) => action$.pipe(
    filter(resetHiddenMap.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { viewId } = state.multiViewer.viewActive;
        // const viewIdFinal = GlobalState.getViewId(viewId);
        // const viewer = GlobalState.getViewer3D(viewIdFinal);
        const viewer = GlobalState.getViewer3D(viewId);
        if (viewer) {
            (viewer.model as CModel).clearVisibilityMap();
        }
        return [
            treeSlice.actions.toggleVisibilityChangedCallback(viewId)
        ]
    })
);

export const TreeEpics = [
    // fetchDataTree$,
    // fetchDataTreeByViewIdActive$,
    fetchDataTreeFlat$,
    resetHiddenMap$,
    deleteStateByViewIdTree$
]
export const {
    // fetchDataTree,
    // fetchDataTreeByViewIdActive,
    // error,
    // setLoading: setLoadingTree,
    fetchDataTreeFlat,
    setTreeMapData,
    toggleVisibilityChangedCallback,
    resetHiddenMap,
    setUpdateShowHide,
    setCurrentTreeMap,
    setUpdateHoopsVisibleState,
    deleteStateByViewIdTree,
    storeTreeMapData,
    storeTreeLoadingMap,
    refreshTree
} = treeSlice.actions;
export default treeSlice.reducer;
