/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-extra-boolean-cast */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DragAndDropResult, FileInfo, Layout } from "common/define";
import { GlobalState } from "common/global";
import { PayloadChecked, PayloadSelectedFileAndLayout, RootEpic } from "common/type-state";
import PdfHelper from "container/pdf-viewer/helper/pdf.helper";
import { MarkupTextBoxItem } from "container/viewer3d/markup/markup-items/markup.textbox.item";
import { merge } from "rxjs";
import { catchError, concatMap, filter, map, mergeMap, switchMap, withLatestFrom } from "rxjs/operators";
import { Lodash } from "utils/utils";
import { deleteStateByViewId, setAppLoading } from "./app.slice";
import { addCombineModel, deleteMultiChild } from "./combine.slice";
import { MultiViewerHelper } from './helper';
import { setMarkupMode, setMarkupViewSelected } from "./markup.slice";
import { fetchMarkup3d } from "./markup3d.slice";
import { setEnableRedoBtn, setEnableSaveBtn, setEnableUndoBtn } from "./markupToolbar.slice";
import { deleteFLoatingVisibleByViewId } from "./panelVisible.slice";
import { activeSheetData, activeSheetMergeData } from "./sheets.slice";

const initState: MultiViewerHelper.MultiViewerState = {
    listViewerDisplay: [],
    viewActive: {
        viewId: '',
        baseFileId: null,
        extension: '',
        modelFileId: ''
    },
    layout: Layout.Full,
    listViewerVisible: [],
    listViewerBlank: [],
    listViewOnly: [],
    isMarkupDragging: false,
    synchronized: false,
};

const multiViewerSlice = createSlice({
    name: 'multiViewer',
    initialState: initState,
    reducers: {
        setLayout(state, action: PayloadAction<Layout>) {
            state.layout = action.payload;
        },
        firstFileDisplay(state) { return },
        setViewActive(state, action: PayloadAction<ViewId>) { return },
        addViewerDisplay(state, action: PayloadAction<FileInfo[]>) { return },
        selectViewerMerge(state, action: PayloadAction<FileInfo>) { return },
        addViewerDisplayComplete(state, action: PayloadAction<MultiViewerHelper.ResultHandleMultiView>) {
            const { newListViewerDisplay, newListViewerVisible, newViewActive } = action.payload;
            newListViewerDisplay && (state.listViewerDisplay = newListViewerDisplay);
            newListViewerVisible && (state.listViewerVisible = newListViewerVisible);
            newViewActive && (state.viewActive = newViewActive);
            GlobalState.redrawViewer()
        },
        closeViewer(state, action: PayloadAction<ViewId>) { return },
        closeViewerComplete(state, action: PayloadAction<MultiViewerHelper.ResultHandleMultiView>) {
            const { newListViewerBlank, newListViewerDisplay, newListViewerVisible, newViewActive, newLayout } = action.payload;
            newListViewerBlank && (state.listViewerBlank = newListViewerBlank);
            newListViewerDisplay && (state.listViewerDisplay = newListViewerDisplay);
            newListViewerVisible && (state.listViewerVisible = newListViewerVisible);
            newViewActive && (state.viewActive = newViewActive);
            newLayout && (state.layout = newLayout)
        },
        checkedFile(state, action: PayloadAction<PayloadChecked>) { return },
        selectedFile(state, action: PayloadAction<ViewId>) {
            const findFile = state.listViewerVisible.find(f => GlobalState.getViewId(f) === action.payload);
            const viewIdActive = GlobalState.getViewId(state.viewActive.viewId);
            if (findFile && findFile !== viewIdActive) {
                state.viewActive = MultiViewerHelper.createViewActive(findFile, [...state.listViewerDisplay]);
            }
        },
        setSynchronized(state, action: PayloadAction<boolean>) {
            state.synchronized = action.payload;
            GlobalState.setSynchronized(action.payload);
        },
        selectFileAndLayout(state, action: PayloadAction<PayloadSelectedFileAndLayout>) { return },
        createViewOnly(state) { return },
        dragAndDropViewer(state, action: PayloadAction<DragAndDropResult>) { return },
        updateStateHandleMultiView(state, action: PayloadAction<MultiViewerHelper.ResultHandleMultiView>) {
            const { newListViewerDisplay, newListViewerVisible, newViewActive, newListViewerBlank, newLayout, newListViewOnly } = action.payload;
            newListViewerDisplay && (state.listViewerDisplay = newListViewerDisplay);
            newListViewerVisible && (state.listViewerVisible = newListViewerVisible);
            newListViewerBlank && (state.listViewerBlank = newListViewerBlank);
            newViewActive && (state.viewActive = newViewActive);
            newLayout && (state.layout = newLayout);
            newListViewOnly && (state.listViewOnly = newListViewOnly)
        },
        errorMultiViewer(state) {
            return;
        },
        setIsMarkupDragging(state, action: PayloadAction<boolean>) {
            state.isMarkupDragging = action.payload;
            const viewIdActive = GlobalState.getViewId(state.viewActive.viewId);
            GlobalState.mapDraggingActive.set(viewIdActive, action.payload);
        },
        updateViewActive(state, action: PayloadAction<MultiViewerHelper.ResultHandleMultiView>) { return },
        activateMarkupViewSelected(state, action: PayloadAction<string | null | undefined>) { return },
        onChangeViewer(state, action: PayloadAction<undefined>) { return },
        deleteStateByViewIdMultiView(state, action: PayloadAction<ViewId>) {
            const viewId = action.payload;
            const listViewDisplay = Lodash.cloneDeep(state.listViewerDisplay);
            state.listViewerDisplay = listViewDisplay.filter(v => v.viewId !== viewId);
        }
    }
});

const viewActive$: RootEpic = (action$, state$) => action$.pipe(
    filter(setViewActive.match),
    withLatestFrom(state$),
    switchMap(([{ payload: viewId }, state]) => {
        const viewActive = state.multiViewer.viewActive;
        const currViewID = viewActive.viewId;
        if (viewActive.formatViewer === 'Foxit') {
            const pdfViewer = GlobalState.getPdfViewer(currViewID);

            if (pdfViewer) {
                const stateHandler = pdfViewer.getStateHandlerManager();
                stateHandler && stateHandler.switchTo('select-text-image');
                const tempDiv = document.getElementsByClassName('pdf-textbox-temp');
                const pageHandle = PdfHelper.pageHandler(pdfViewer)
                const pageUi = pageHandle[0] as HTMLElement;
                while (tempDiv[0]) {
                    pageUi.parentElement && pageUi.parentElement.removeChild(tempDiv[0])
                }
            }
        } else {
            const viewer = GlobalState.getViewer3D(viewActive.viewId);
            const markupAction = GlobalState.getMarkupAction(currViewID);

            if (viewer && markupAction && markupAction.markupData) {

                //hide markup when change view
                markupAction.markupData.markupItems.forEach(v => {
                    if (v instanceof MarkupTextBoxItem) v.blurTextArea();
                    v.setMarkupVisible(false);
                    v.setSelected(false);
                });
                // unregis pin when change view
                markupAction.markupData.markupPinMarker.forEach(m => {
                    const id = m.getMarkupItemId();
                    if (id) {
                        viewer.markupManager.unregisterMarkup(id);
                        viewer.markupManager.removeMarkupElement(id);
                        m.remove();
                        viewer.markupManager.refreshMarkup();
                    }
                });
                markupAction.markupData.markupPinMarker = [];
                markupAction.markupData.updateMarkupPinMarker();
                viewer.markupManager.refreshMarkup();
            }
        }
        return MultiViewerHelper.setViewActive$(viewId, state.multiViewer).pipe(
            switchMap(result => [
                multiViewerSlice.actions.updateStateHandleMultiView(result),
                updateViewActive(result),
                onChangeViewer(),
                fetchMarkup3d(),
            ])
        )
    })
)

const updateViewActive$: RootEpic = (action$, state$) => action$.pipe(
    filter(updateViewActive.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { newViewActive } = action.payload;
        if (newViewActive) {
            if (newViewActive.formatViewer === 'Foxit') {
                //
            }
            else {
                const viewer = GlobalState.getViewer3D(newViewActive.viewId);
                const markupAction = GlobalState.getMarkupAction(newViewActive.viewId);

                if (viewer && markupAction && markupAction.markupData) {
                    markupAction.markupData.markupItems.forEach(v => {
                        if (v instanceof MarkupTextBoxItem) v.blurTextArea();
                        v.setMarkupVisible(true);
                    })
                    viewer.markupManager.refreshMarkup();
                }
            }
        }

        return []
    }))

const firstFileDisplay$: RootEpic = (action$, state$) => action$.pipe(
    filter(firstFileDisplay.match),
    withLatestFrom(state$),
    switchMap(([_, state]) => {
        const [firstFile,] = state.filesList.filesList;
        if (firstFile?.viewId) {
            return [addViewerDisplay([firstFile])]
        }
        return []
    })
)
const closeViewer$: RootEpic = (action$, state$) => action$.pipe(
    filter(closeViewer.match),
    withLatestFrom(state$),
    concatMap(([{ payload: viewId }, state]) => {
        return merge(
            [setAppLoading(true)],
            MultiViewerHelper.closeViewerObser(viewId, state.multiViewer).pipe(
                mergeMap(resultClose => [
                    closeViewerComplete(resultClose),
                    deleteStateByViewId(viewId),
                ])
            ).pipe(catchError(err => [setAppLoading(false)]))
        )
    })
)
const addViewerDisplay$: RootEpic = (action$, state$) => action$.pipe(
    filter(addViewerDisplay.match),
    withLatestFrom(state$),
    concatMap(([action, state]) => {
        return MultiViewerHelper.addViewerDisplayObser(action.payload, state.multiViewer).pipe(
            map(resultAddView => multiViewerSlice.actions.addViewerDisplayComplete(resultAddView))
        )
    })
)
const selectViewerMerge$: RootEpic = (action$, state$) => action$.pipe(
    filter(selectViewerMerge.match),
    withLatestFrom(state$),
    concatMap(([action, state]) => {
        return MultiViewerHelper.selectViewerMergeObser(action.payload, state.multiViewer).pipe(
            map(resultAddView => multiViewerSlice.actions.addViewerDisplayComplete(resultAddView))
        )
    })
)
const checkedFile$: RootEpic = (action$, state$) => action$.pipe(
    filter(checkedFile.match),
    withLatestFrom(state$),
    concatMap(([action, state]) => {
        const { listViewerVisible, listViewerBlank, viewActive } = state.multiViewer;

        const { viewId, value, isReplaceViewActive } = action.payload;
        // const multiViewerState = state.multiViewer
        const listVisibleMapMerge = GlobalState.mapListMergeCombine(listViewerVisible);
        const baseParentViewId = GlobalState.getViewIdCombine(viewId);
        const combineStatus = GlobalState.getCombineFileStatus(viewId);
        let childs: CombineChild[] = [];
        if (isReplaceViewActive && !value) {
            const listCombineChile = state.combineModel.mapCombineModel[baseParentViewId];//GlobalState.mapCombineModel.get(baseParentViewId);
            if (listCombineChile) {
                if (combineStatus === 1) {
                    childs = listCombineChile;

                } else {
                    const combineChile = listCombineChile.filter(v => v.viewId === viewId);
                    if (combineChile && combineChile.length > 0) childs = combineChile;
                }
            }
        }

        if (listVisibleMapMerge.includes(baseParentViewId) && !value) {
            if (combineStatus !== 2) { // parent, undefine
                return merge(
                    [setAppLoading(true)],
                    [deleteFLoatingVisibleByViewId(viewId)],
                    [deleteMultiChild(childs)],
                    MultiViewerHelper.replaceViewerByBlank(viewId, state.multiViewer).pipe(
                        mergeMap(result => [
                            multiViewerSlice.actions.updateStateHandleMultiView(result),
                            setAppLoading(false)
                        ])
                    )
                )
            }
            else { // child
                const baseViewId = GlobalState.getViewId(viewId);
                const listSheetData = GlobalState.mapSheetData.get(baseParentViewId);
                const baseViewId1 = GlobalState.getViewId(viewActive.viewId);
                const sheetData = listSheetData?.find(v => v.viewId === baseParentViewId);
                if (baseViewId === baseViewId1 && baseViewId !== baseParentViewId && sheetData) {
                    return merge(
                        [setAppLoading(true)],
                        // [child ? deleteChildCombined(child) : noProcess()],
                        [!sheetData.viewId ? activeSheetData({ viewId: viewId, id: sheetData.id, childsDelete: childs }) : activeSheetMergeData({ viewId: viewId, sheetData, childsDelete: childs })],
                    )
                }
                else return merge(
                    [setAppLoading(true)],
                    [deleteMultiChild(childs)],
                    [setAppLoading(false)]
                )
            }
        }
        if (isReplaceViewActive && combineStatus !== 1) {
            if (value) {
                if (viewActive?.baseFileId) return [addCombineModel([viewId])];
            }
        }
        const listViewBlankVisible = listViewerBlank.filter(v => listViewerVisible.includes(v));

        if (value && (listViewBlankVisible.length > 0 || (!isReplaceViewActive && !viewActive.baseFileId))) {
            const fileInfoChecked = state.filesList.filesList.find(f => f.viewId === viewId);
            return merge(
                [setAppLoading(true)],
                MultiViewerHelper.replaceBlankByViewer(viewId, state.multiViewer, listViewBlankVisible, fileInfoChecked).pipe(
                    mergeMap(result => [
                        multiViewerSlice.actions.updateStateHandleMultiView(result),
                        setAppLoading(false)
                    ])
                )
            )
        }

        return []
    })
)
const selectFileAndLayout$: RootEpic = (action$, state$) => action$.pipe(
    filter(selectFileAndLayout.match),
    withLatestFrom(state$),
    concatMap(([action, state]) => {
        const { layout, listFiles } = action.payload;
        const fileInfoResult = state.filesList.filesOrigin.filter(f => listFiles.includes(f.viewId));
        return MultiViewerHelper.selectFilesAndLayoutObser(layout, fileInfoResult, state.multiViewer).pipe(
            map(result => multiViewerSlice.actions.updateStateHandleMultiView(result))
        )
    })
)
const createViewOnly$: RootEpic = (action$, state$) => action$.pipe(
    filter(createViewOnly.match),
    withLatestFrom(state$),
    concatMap(([_, state]) => {
        return MultiViewerHelper.createViewOnlyObser(state.multiViewer).pipe(
            map(result => multiViewerSlice.actions.updateStateHandleMultiView(result))
        )
    })
)
const dragAndDropViewer$: RootEpic = (action$, state$) => action$.pipe(
    filter(dragAndDropViewer.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const resultDropped = action.payload;
        const multiViewerState = state.multiViewer;
        const fromFileInfo = state.filesList.filesList.find(f => f.viewId === resultDropped.from);
        return MultiViewerHelper.dragAndDropViewerHelper(resultDropped, multiViewerState, fromFileInfo).pipe(
            map(result => multiViewerSlice.actions.updateStateHandleMultiView(result))
        )
    })
)

const activateMarkupViewSelected$: RootEpic = (action$, state$) => action$.pipe(
    filter(activateMarkupViewSelected.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { viewId } = state.multiViewer.viewActive;
        const viewer = GlobalState.getViewer3D(viewId);
        const markupViewSelected = action.payload;
        if (viewer && markupViewSelected) viewer.markupManager.activateMarkupViewWithPromise(markupViewSelected);
        return [setMarkupViewSelected(markupViewSelected ? markupViewSelected : null)]
    })
)
const onChangeViewer$: RootEpic = (action$, state$) => action$.pipe(
    filter(onChangeViewer.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { viewId } = state.multiViewer.viewActive;
        const getMarkupMode = GlobalState.mapMarkupMode.get(viewId);
        const getEnableSaveBtn = GlobalState.mapEnableSaveBtn.get(viewId);
        const getEnableUndoBtn = GlobalState.mapEnableUndoBtn.get(viewId);
        const getEnableRedoBtn = GlobalState.mapEnableRedoBtn.get(viewId);
        const isMarkupDragging = GlobalState.mapDraggingActive.get(viewId);
        const markupViewSelected = GlobalState.mapMarkupViewSelected.get(viewId);
        return merge(
            [setMarkupMode(getMarkupMode ? getMarkupMode : 'viewMode')],
            [setEnableSaveBtn(!!getEnableSaveBtn)],
            [setEnableUndoBtn(!!getEnableUndoBtn)],
            [setEnableRedoBtn(!!getEnableRedoBtn)],
            [setIsMarkupDragging(!!isMarkupDragging)],
            [activateMarkupViewSelected(markupViewSelected)],
        )
    })
)

export const MultiViewerEpics = [
    closeViewer$,
    addViewerDisplay$,
    selectViewerMerge$,
    viewActive$,
    firstFileDisplay$,
    checkedFile$,
    selectFileAndLayout$,
    createViewOnly$,
    dragAndDropViewer$,
    updateViewActive$,
    activateMarkupViewSelected$,
    onChangeViewer$,
];

export const {
    setLayout,
    firstFileDisplay,
    setViewActive,
    errorMultiViewer,
    addViewerDisplay,
    selectViewerMerge,
    closeViewer,
    closeViewerComplete,
    checkedFile,
    selectedFile,
    selectFileAndLayout,
    createViewOnly,
    dragAndDropViewer,
    setIsMarkupDragging,
    updateViewActive,
    activateMarkupViewSelected,
    onChangeViewer,
    setSynchronized,
    deleteStateByViewIdMultiView
} = multiViewerSlice.actions;
export default multiViewerSlice.reducer;