import { FileExtension, FileInfo } from "common/define";
import { GlobalState } from "common/global";
import { PayloadActiveSheet, SheetData } from "common/type-state";
import { Observable } from "rxjs";
import Utils, { Lodash } from "utils/utils";

const SHEET_ID_3D = -1211;

/** functions */
function createSheet3D(viewer: Communicator.WebViewer): SheetData[] {
    const nodeChildrenIds = viewer.sheetManager.get3DNodes();
    const sheet3D: SheetData = {
        id: SHEET_ID_3D,
        name: '3D Model',
        is3D: true,
        nodeIds: nodeChildrenIds?.length > 0 ? nodeChildrenIds : [viewer.model.getAbsoluteRootNode()]
    };
    return [sheet3D]
}
function loadListSheetDefault(viewer: Communicator.WebViewer): SheetData[] | undefined {
    const numSheet = viewer.sheetManager.getSheetIds();
    if (numSheet && numSheet.length > 0) {
        return numSheet.map((num) => {
            const sheet: SheetData = {
                id: num,
                name: viewer.model.getNodeName(num) ?? '',
                nodeIds: [num],
                is3D: false
            };
            return sheet
        });
    }
}
function loadListSheetOneModel(viewId: ViewId, fileInfo: FileInfo): SheetData[] {
    const webViewer = GlobalState.getViewer3D(viewId);
    if (webViewer) {
        const getExt = Utils.getFileExtension(fileInfo.filename);
        const sheet3D = getExt === 'dgn' ? [] :createSheet3D(webViewer);
        const listSheet = loadListSheetDefault(webViewer) ?? [];
        return [...sheet3D, ...listSheet];
    }
    return []
}
function createListSheetDataFromFileInfo(listViewId: ViewId[], fileListOrigin: FileInfo[]): SheetData[] {
    const listFileInfo = fileListOrigin.filter(f => listViewId.includes(f.viewId));
    return listFileInfo.map(f => {
        const sheetId = f.viewId;
        const sheet: SheetData = {
            id: sheetId,
            name: f.cacheFilename ?? f.filename ?? '',
            nodeIds: [],
            viewId: f.viewId,
            is3D: false
        };
        return sheet
    })
}
/** functions */

/** function export */
export function loadSheetDefaultObser(viewId: ViewId, fileListOrigin: FileInfo[], isCombine = false): Observable<SheetData[]> {
    return new Observable<SheetData[]>(obser => {
        const viewIdParent = GlobalState.getViewId(viewId);
        let prevSheetData = GlobalState.mapSheetData.get(viewIdParent);
        if (isCombine && prevSheetData) {
            prevSheetData = prevSheetData.filter(v => !v.is3D);
        }
        if (prevSheetData) {
            obser.next(prevSheetData);
            obser.complete()
        } else {
            const mergeFiles = GlobalState.getParentChildrenMerge(viewIdParent);
            if (!mergeFiles) {
                const fileInfoParent = fileListOrigin.find(f => f.viewId === viewIdParent);
                if (fileInfoParent) {
                    const result = loadListSheetOneModel(viewIdParent, fileInfoParent);
                    obser.next(result);
                    GlobalState.mapSheetData.set(viewIdParent, result);
                }
                obser.complete()
            } else {
                const {parentId, children} = mergeFiles;
                const fileInfoParent = fileListOrigin.find(f => f.viewId === parentId);
                const webViewer = GlobalState.getViewer3D(parentId);
                let resultParentId: SheetData[] = [];
                if (webViewer && fileInfoParent) {
                    resultParentId = createSheet3D(webViewer);
                    resultParentId[0].viewId = parentId;
                    // In case file root === 0
                    if (fileInfoParent.multiStream && fileInfoParent.isRootModel === 0) {
                        resultParentId[0].name = fileInfoParent.cacheFilename || fileInfoParent.filename
                    }
                }
                const childrenWithoutParent = Lodash.remove([...children], v => v !== parentId);
                const resultChildren = createListSheetDataFromFileInfo(childrenWithoutParent, fileListOrigin);
                let result = [...resultParentId, ...resultChildren];
                // in the revit file, only show the 3d model for CMIC
                const ext = Utils.getFileExtension(fileInfoParent?.filename);
                if (ext === FileExtension.Revit) {
                    result = [...resultParentId];
                    resultChildren.forEach(v => {
                        const file = fileListOrigin.find(f => f.viewId === v.viewId);
                        if (file?.isRootModel === 1) result.push(v);
                    });
                }
                // sort result based on fileListOrigin
                const sortResult: SheetData[] = [];
                fileListOrigin.forEach(f => {
                    const sheetItem = result.find(s => s.viewId === f.viewId);
                    if (f.isRootModel === 1 && sheetItem) {
                        sheetItem.name = '3D Model';
                        sheetItem.is3D = true;
                    }
                    sheetItem && sortResult.push(sheetItem);
                });
                //
                obser.next(sortResult);
                GlobalState.mapSheetData.set(parentId, sortResult);
                obser.complete()
            }
        }
    })
}
export function activeSheetDataObser(payload: PayloadActiveSheet): Observable<void> {
    return new Observable<void>(obser => {
        const {viewId, id} = payload;
        const webViewer = GlobalState.getViewer3D(viewId);
        if (webViewer) {
            if (id !== SHEET_ID_3D && typeof id === 'number') {
                webViewer.model.setViewAxes(new Communicator.Point3(0, 0, 1), new Communicator.Point3(0, 1, 0));
                webViewer.setViewOrientation(Communicator.ViewOrientation.Front, 0).then(async (v) => {
                    webViewer.sheetManager.setActiveSheetId(id).then(_ => {
                        obser.next();
                        obser.complete();
                    })
                });
            } else {
                obser.next();
                webViewer.sheetManager.deactivateSheets()
            }
        } else {
            obser.complete();
        }
    })
}

export function findValidSheetName(listCurrent: SheetData[], name: string): string {
    let n = 1;
    let newName = name;
    if (listCurrent && listCurrent.length > 0) {
        const listName = listCurrent.map((v) => v.name);
        while (listName.includes(newName)){
            newName = name + '_' + n;
            n = n + 1;
        }        
    }
    return n > 1 ? newName : '';
}
